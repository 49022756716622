////////// BACKGROUND COLOR //////////
.bg-primaryColor {
  background-color: rgba(255, 255, 255, 1);
}
.bg-secondaryColor {
  background-color: rgba(255, 255, 255, 1);
}
.bg-thirdColor {
  background-color: rgba(255, 255, 255, 1);
}
.bg-fourthColor {
  background-color: rgba(231, 202, 30, 1);
}
.bg-fifthColor {
  background-color: rgba(231, 202, 30, 0.73);
}
.bg-sixthColor {
  background-color: rgba(226, 226, 226, 1);
}
.bg-seventhColor {
  background-color: rgba(239, 239, 239, 1);
}
